import CongregacaoService from "../../services/CongregacaoService";

export const actions = {
    async fetchCongregacoesBySetor({ commit }, {setor, headers = {}}) {
        try {
            commit('SET_ERROR', '');
            commit('SET_ERRORS', []);
            commit('SET_FILTRO_CONGREGACOES', []);

            const response = await CongregacaoService.getBySetor(setor, headers);
            if (response.errors) {
                let error = "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                commit('SET_FILTRO_CONGREGACOES', []);
                return;
            }
            commit('SET_FILTRO_CONGREGACOES', response.data.getCongregacoesBySetor);
        } catch (error) {
            let errorMessage = "Ocorreu um erro ao realizar busca na API";
            commit('SET_ERROR', errorMessage);
            commit('SET_ERRORS', response.errors || []);
            commit('SET_FILTRO_CONGREGACOES', []);
            console.error(error);
        }

    },
    async fetchCongregacoes({ commit }, headers = {}) {
        try {
            commit('SET_ERROR', '');
            commit('SET_ERRORS', []);
            commit('SET_CONGREGACOES', []);

            const response = await CongregacaoService.getById(headers);
            if (response.errors) {
                let error = "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                commit('SET_CONGREGACOES', []);
                return;
            }
            commit('SET_CONGREGACOES', response.data.congregacoesByUser);
        } catch (error) {
            let errorMessage = "Ocorreu um erro ao realizar busca na API";
            commit('SET_ERROR', errorMessage);
            commit('SET_ERRORS', response.errors || []);
            commit('SET_CONGREGACOES', []);
            console.error(error);
        }

    },
    async fetchSetores({ commit }, headers = {}) {

        try {
            commit('SET_ERROR', '');
            commit('SET_ERRORS', []);
            commit('SET_FILTRO_SETORES', []);

            const response = await CongregacaoService.getSetores(headers);
            if (response.errors) {
                let error = "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                commit('SET_FILTRO_SETORES', []);
                return;
            }
            commit('SET_FILTRO_SETORES', response.data.getSetores);
        } catch (error) {
            console.error(error);
        }

    },
}
