import CompraService from "../../services/CompraService";
import router from "../../router";

export const actions = {
    setItemSugeridoEditar({ commit }, item){
        console.log("cabra macho", item);
        commit("SET_ITEM_SUGERIDO", item);
    },
    async deleteItemSugerido({ commit }, { item_id, headers = {} }) {

        try {
            commit('SET_ERROR', "");
            commit('SET_ERRORS', []);
            commit('SET_SUCCESS', "");
            const response = await CompraService.deleteItemSugerido(item_id, headers);

            if (response.errors) {
                let error = "Ocorreu um erro ao remover o item sugerido";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                return;
            }

            commit('SET_SUCCESS', "Item sugerido removido com sucesso");

            return true;
        } catch (error) {
            return false;
        }
    },
    async fetchCompraItensSugeridos({ commit }, { compra_id, headers = {} }) {
        try {
            commit('SET_ERROR', '');
            commit('SET_ERRORS', []);
            commit('SET_COMPRA_ITENS_SUGERIDOS', []);

            const response = await CompraService.getItensSugeridos(compra_id, headers);
            if (response.errors) {
                let error = "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                commit('SET_COMPRA_ITENS_SUGERIDOS', []);
                return;
            }
            commit('SET_COMPRA_ITENS_SUGERIDOS', response.data.compraItensSugeridos);
        } catch (error) {
            console.error(error);
        }
    },
    async adicionarItensSugeridosCompra({ commit }, { compra_id, produto_servico, motivo_sugestao, quantidade, headers = {} }) {

        try {
            commit('SET_SUCCESS', "");
            const response = await CompraService.adicionarItensSugeridosCompra({
                compra_id,
                produto_servico,
                motivo_sugestao,
                quantidade
            }, headers);

            if (response.errors) {
                let error = "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                return;
            }

            commit('SET_SUCCESS', "Item sugerido adicionado com sucesso");

            return response;
        } catch (error) {
            return false;
        }
    },
    async editarItensSugeridosCompra({ commit }, { id, compra_id, produto_servico, motivo_sugestao, quantidade, headers = {} }) {

        try {
            commit('SET_SUCCESS', "");
            const response = await CompraService.editarItensSugeridosCompra({
                id,
                compra_id,
                produto_servico,
                motivo_sugestao,
                quantidade
            }, headers);

            if (response.errors) {
                let error = "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                return;
            }

            commit('SET_SUCCESS', "Item sugerido editado com sucesso");

            return response;
        } catch (error) {
            return false;
        }
    },
    async atualizarOrcamento({ commit }, { item_id, orcamento, headers = {} }) {

        try {
            commit('SET_SUCCESS', "");
            const response = await CompraService.atualizarOrcamento({
                item_id,
                orcamento
            }, headers);

            if (response.errors) {
                let error = "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                return;
            }

            commit('SET_SUCCESS', "Item atualizado com sucesso");

            return true;
        } catch (error) {
            return false;
        }
    },
    async atualizarTipo({ commit }, { tipo_id, compra_id, headers = {} }) {
        try {
            commit('SET_SUCCESS', "");
            const response = await CompraService.atualizarTipo({
                tipo_id,
                compra_id,
            }, headers);

            console.log("response", response);

            if (response.errors) {
                console.log("errors", response.errors);
                let error = response.errors[0].message || "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                return false;
            }

            commit('SET_SUCCESS', "Tipo da compra atualizado com sucesso");

            return true;
        } catch (error) {
            return false;
        }
    },
    async atualizarStatus({ commit }, { tipo_id, status_id, compra_id, justificativa = null, documentos = [], headers = {} }) {
        try {
            const response = await CompraService.atualizarStatus({
                tipo_id,
                status_id,
                compra_id,
                justificativa,
                documentos
            }, headers);

            console.log("response", response);

            if (response.errors) {
                console.log("errors", response.errors);
                let error = response.errors[0].message || "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                return false;
            }

            return true;
        } catch (error) {
            return false;
        }
    },
    async updateItem({ commit }, { id, imagem, produto_servico, quantidade, headers = {} }) {
        try {
            commit('SET_SUCCESS', "");
            const response = await CompraService.updateItem({
                id,
                imagem,
                produto_servico,
                quantidade
            }, headers);

            if (response.errors) {
                console.log("errors", response.errors);
                let error = response.errors[0].message || "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                return false;
            }

            commit('SET_SUCCESS', "Item atualizado com sucesso");

            return true;
        } catch (error) {
            return false;
        }
    },
    setDocumentos({ commit }, { documentos }) {
        commit("SET_DOCUMENTOS", documentos);
    },
    setDocumento({ commit }, { documento }) {
        commit("SET_DOCUMENTO", documento);
    },
    async fetchStatusByUserAndCompra({ commit }, { compra_id, headers = {} }) {
        try {
            commit('SET_ERROR', '');
            commit('SET_ERRORS', []);
            commit('SET_STATUS', []);

            const response = await CompraService.getStatusByUserAndCompra(compra_id, headers);
            if (response.errors) {
                let error = "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                return;
            }
            commit('SET_STATUS', response.data.statusByUserAndCompra);
            return response.data.statusByUserAndCompra;
        } catch (error) {
            console.error(error);
            return [];
        }
    },
    async fetchTipos({ commit }, headers = {}) {

        try {
            commit('SET_ERROR', '');
            commit('SET_ERRORS', []);

            const response = await CompraService.getTipos(headers);
            if (response.errors) {
                let error = "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                commit('SET_TIPOS', []);
                return;
            }
            commit('SET_TIPOS', response.data.tipos);
            return response.data.tipos;
        } catch (error) {
            console.error(error);
        }

    },
    async fetchStatus({ commit }, headers = {}) {

        try {
            commit('SET_ERROR', '');
            commit('SET_ERRORS', []);

            const response = await CompraService.getStatus(headers);
            if (response.errors) {
                let error = "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                commit('SET_STATUS', []);
                return;
            }
            commit('SET_STATUS', response.data.status);
        } catch (error) {
            console.error(error);
        }

    },
    async fetchCompra({ commit }, { id, headers = {} }) {
        try {
            commit('SET_ERROR', '');
            commit('SET_ERRORS', []);

            const response = await CompraService.getCompra(id, headers);
            if (response.errors) {
                let error = "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                commit('SET_COMPRA', {});
                return;
            }
            commit('SET_COMPRA', response.data.compra);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchCompras({ commit }, { page = 1, filters = {}, headers = {} }) {
        try {
            commit('SET_ERROR', '');
            commit('SET_ERRORS', []);

            const response = await CompraService.getCompras(page, filters, headers);
            if (response.errors) {
                let error = "Ocorreu um erro ao realizar busca na API";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                commit('SET_DATA_COMPRAS', {});
                return;
            }
            commit('SET_DATA_COMPRAS', response.data.compras);
        } catch (error) {
            console.error(error);
        }
    },
    async saveCompra({ commit }, { compra, headers }) {
        try {
            commit('SET_SUCCESS', '');
            commit('SET_ERROR', '');
            commit('SET_ERRORS', []);

            let response = await CompraService.criarCompra(compra, headers);

            if (response.errors) {
                let error = "Ocorreu um erro ao solicitar uma compra";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                commit('SET_DATA_COMPRAS', {});
                return;
            }

            router.push({
                path: '/solicitacoes/success'
            });

        } catch (error) {
            console.error(error);
        }
    },
    async parecerCoordenador({ commit }, { parecer ,headers }) {
        try {
            commit('SET_SUCCESS', '');
            commit('SET_ERROR', '');
            commit('SET_ERRORS', []);

            let response = await CompraService.parecerCoordenador(parecer, headers);

            if (response.errors) {
                let error = "Ocorreu um erro ao dar um parecer sobre a compra";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                return;
            }

            if (parecer.aprovado) {
                commit("SET_PARECER_COORDENADOR", {
                    aprovado: true
                });
            } else {
                commit("SET_PARECER_COORDENADOR", {
                    aprovado: false
                });
            }
            
            router.push({
                path: '/solicitacoes/success'
            });


        } catch (error) {
            console.error(error);
        }
    },
    async cancelarCompra({ commit }, { compraId, headers }) {
        try {
            commit('SET_SUCCESS', '');
            commit('SET_ERROR', '');
            commit('SET_ERRORS', []);

            let response = await CompraService.cancelarCompra(compraId, headers);

            if (response.errors) {
                let error = "Ocorreu um erro ao cancelar esta compra";
                commit('SET_ERROR', error);
                commit('SET_ERRORS', response.errors);
                return;
            }

            commit('SET_SUCCESS', 'Cancelamento realizado com sucesso');

            router.push({
                path: '/solicitacoes'
            });

        } catch (error) {
            console.error(error);
        }
    },
    setFiltro({ commit }, filtro) {
        commit("SET_FILTRO", filtro);
    },
}