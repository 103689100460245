import router from "../../router"
import UserService from "../../services/UserService"

export const actions = {
    setTokenLaravel({ commit }, tokenLaravel) {
        commit("SET_TOKEN_LARAVEL", tokenLaravel)
    },
    setShowMenu({ commit }, showMenu) {
        commit("SET_SHOW_MENU", showMenu)
    },
    setUser({ commit }, user) {
        commit('SET_USER', user)
    },
    async fetchUser({ commit }, headers = {}) {
        try {
            commit('SET_USER', {});
            let response = await UserService.getAuthUser(headers);
            if (response.errors) {
                if (response.errors[0].extensions.code == 'UNAUTHORIZED') {
                    router.push({
                        path: '/error',
                        query: {
                            title: 'Não autorizado',
                            subtitle: response.errors[0].message
                        }
                    });
                }
                return;
            }
            commit('SET_USER', response.data);
        } catch (error) {
            console.error(error);
            if(error.response.status == 401){
                router.push({
                    path: '/error',
                    query: {
                        title: 'Não autorizado',
                        subtitle: error.response.data.message || ''
                    }
                });
            }
        }
    }
}