import { ElNotification } from "element-plus";

export const mutations = {
    SET_ERROR(state, error) {
        state.error = error;

        if(error != ''){
            ElNotification({
                title: 'Error',
                message: error,
                type: 'error',
            })
        }
    },
    SET_SUCCESS(state, success) {
        state.success = success;

        if(success != ''){
            ElNotification({
                title: 'Sucesso',
                message: success,
                type: 'success',
            })
        }
    },
    SET_ERRORS(state, errors) {
        state.errors = errors;
    },
};