<template>
  <div class="body">
    <div class="header" style="display: flex; flex-direction: ;">
      <el-radio-group>
        <button class="menuButton" @click="abrirMenu" v-show="$route.path != '/solicitacao/create/sucess'">
          <el-icon class="iconeMenu"
            style="width: 30px; color: white !important; margin: 10px !important; margin-left: 20px !important;">
            <!-- <Menu /> -->
            <Expand v-show="isCollapse" />
            <Fold v-show="!isCollapse" />
          </el-icon>

        </button>
        <a href="/solicitacao/create">
          <button class="menuButton" v-show="$route.path == '/solicitacao/create/sucess'">
            <el-icon class="iconeMenu"
              style="width: 30px; color: white !important; margin: 10px !important; margin-left: 20px !important;">
              <Back />
            </el-icon>

          </button>
        </a>
      </el-radio-group>
      <h3 class="tituloMenu" v-show="$route.path == '/main'">
        Assembleia de Deus no RN
      </h3>
      <h3 class="tituloMenu" v-show="$route.path == '/solicitacoes'">
        Minhas solicitacoes
      </h3>
      <h3 class="tituloMenu" v-show="$route.path == '/solicitacoes/create'">
        Solicitar compra
      </h3>
      <h3 class="tituloMenu" v-show="$route.path == '/solicitacao/edit'">
        Detalhes da solicitacao
      </h3>
      <h3 class="tituloMenu" v-show="$route.path == '/solicitacao/create/sucess'">
        Solicitar compra
      </h3>

      <div>
        <button v-show="$route.path == '/solicitacoes'" class="botaoFiltrar" style="" @click="mostrarFiltro()">
          <Filter style="color: white; width: 24px;" />
        </button>

      </div>

    </div>

    <el-menu default-active="2" class="el-menu-vertical-demo my-menu" :collapse="isCollapse" @open="handleOpen"
      @close="handleClose" style="height: 100%;  border: none;">
      <el-menu-item index="0" style="background-color: white; height: 80px;">
        <template #title>
          <div style="display: flex;">
            <img class="profileImg" src="/img/profile.png" alt="">
            <p class="userName" style="display: block;">Jônatas Campos</p>
          </div>
          <p class="email" style="">essejonatas@gmail.com</p> <br>

        </template>
      </el-menu-item>
      <el-menu-item index="1">
        <template #title>
          <el-icon>
            <House />
          </el-icon>
          <a href="/main">Inicio</a>
        </template>
      </el-menu-item>
      <el-menu-item index="2">
        <template #title>
          <el-icon>
            <Edit />
          </el-icon>
          <a href="">Editar dados</a>
        </template>
      </el-menu-item>
      <el-menu-item index="3">
        <template #title>
          <el-icon>
            <CreditCard />
          </el-icon>
          <a href="/cartao-membro">Credencial</a></template>
      </el-menu-item>
      <el-menu-item index="4">
        <template #title>
          <el-icon>
            <Calendar />
          </el-icon>
          <a href="/agenda">Agenda</a>
        </template>
      </el-menu-item>
      <el-menu-item index="5">
        <template #title>
          <el-icon>
            <ShoppingCartFull />
          </el-icon>
          <a href="/solicitacoes">Compras</a>
        </template>
      </el-menu-item>
      <el-menu-item index="6">
        <template #title>
          <el-icon>
            <Lock />
          </el-icon>
          <a href="">Alterar Senha</a>
        </template>
      </el-menu-item>
      <el-menu-item index="7">
        <template #title>
          <el-icon>
            <Link />
          </el-icon>
          <a href="">Outros Links</a>
        </template>
      </el-menu-item>
      <el-menu-item index="8">
        <template #title>
          <el-icon>
            <Close />
          </el-icon>
          <a href="">Sair</a>
        </template>
      </el-menu-item>
    </el-menu>
  </div>
</template>
  
<script>
import {
  ref,
  onMounted, // Se necessário, você pode usar os hooks do ciclo de vida
} from 'vue';

export default {
  data() {
    return {
      isCollapse: true,
      tituloMenu: [
        { url: '/main', titulo: 'Assembleia de Deus no RN' },

      ],
      mostrar: false
    };
  },
  methods: {
    abrirMenu() {
      this.isCollapse = !this.isCollapse;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    mostrarFiltro() {
      this.mostrar = !this.mostrar
    }
  },
  // Hooks do ciclo de vida, se necessário
  mounted() {
    // ...
  },

  props: {
    usuario: Number,
    titulo: String,
  }
};
</script>
  
<style scoped>
.modal-container {
  width: 100vw;
  height: 100vh;
}

.el-menu-item {
  display: block !important;
}

.profileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 2px #4786FF;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 63px;
}

.userName {
  color: var(--Black-font, #303030);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}

.email {
  margin-left: 123px;
  color: #369FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
  margin-top: -20px;
}

.botaoFiltrar {
  margin-left: 0px;
  display: flex;
  justify-content: center;
  color: white;
  border: none;
  background: transparent;
  margin-top: 28px !important;
  margin-right: 5px;

}

.body {
  margin: auto;
  width: 100%;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #F1F5F9 !important;
  width: 354px;
  min-height: 400px;
}

.my-menu {
  background-color: #fff !important;
  /* Defina a cor de fundo desejada */
}

.el-menu-vertical-demo {
  background-color: #F1F5F9;
  z-index: 1000;
  position: fixed;
  /* Defina a posição como fixed */
  top: 80px;
  /* Ajuste a posição superior conforme necessário */
  left: -63px;
  /* Ajuste a posição esquerda conforme necessário */
}


.header {
  height: 80px;
  width: 100% !important;
  margin-bottom: 20px;
  background-color: #116C9E;
  display: flex;
}

.header h3 {
  margin: auto;
  text-align: center;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
}

.el-icon {
  color: #114C74 !important;

  margin-right: 7px;
  margin-left: 63px;
}

.el-icon svg {
  width: 24px !important;
  height: 24px !important;
}

.botao {
  background-color: transparent;
  margin-left: 20px;
  margin-top: 10px;
  align-items: center;
  border: none;
}

button.menuButton {
  border: none;
  background: transparent !important;
}

.botao svg {
  width: 30px;
  background-color: transparent;
  color: white;
}

a {
  text-decoration: none;
  color: #114C74;
}

.tituloMenu {
  margin: 0px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  text-align: center;
  flex: 1;
}
</style>
  

