import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import App from './App.vue';
import config from './config/enviroment.js';
import router from './router'; // Rotas
import * as ElementPlusIconsVue from '@element-plus/icons-vue' // Icons
import store from './store'; // Vuex

const app = createApp(App);

app.config.globalProperties.$enviroment = config.config;

// Use o Element Plus
app.use(ElementPlus);

// Use o Vue Router
app.use(router);

// Vuex Store
app.use(store);

//carregando os icones do element plus
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.mount('#app');