import GraphQLService from "./GraphQLService";
import { jsonToGraphQLQuery, jsonToGraphQLMutation } from "../utils/JsonToGraphql.js";

const graphqlService = new GraphQLService();

const UserService = {
    async getAuthUser(headers = {}) {
        const query = `
        query {
            user {
                id,
                name,
                email,
                role_id,
                congregacao_id ,
                congregacao {
                    setor
                }
                membro {
                    nome,
                    cargo {
                        nome
                        abreviacao
                    }
                }
                role {
                    id
                }
                roles {
                    id
                }
            }
        }
    `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default UserService;