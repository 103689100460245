import GraphQLService from "./GraphQLService";

const graphqlService = new GraphQLService();

const CongregacaoService = {
    async getBySetor(setor, headers = {}) {
        const query = `
        query {
            getCongregacoesBySetor(setor: [${setor}]) {
                nome
                id
                congregacao_tipo_id
            }
        }
    `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getById(headers = {}) {
        const query = `
        query {
            congregacoesByUser {
                setor
                nome
                id
            }
        }
    `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getSetores(headers = {}) {
        const query = `
        query {
            getSetores {
                setor
                nome
            }
        }
    `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default CongregacaoService;