import { createStore } from 'vuex'
import compras from './compras';
import auth from './auth';
import general from './general';
import congregacoes from './congregacoes';

export default createStore({
    modules: {
        compras,
        auth,
        general,
        congregacoes
    },
})