import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'; // Import your Vuex store

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('../views/solicitarAcesso/SolicitarAcesso.vue')
  // },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('../views/Login.vue')
  // },
  // {
  //   path: '/main',
  //   name: 'Main',
  //   component: () => import('../views/Main.vue')
  // },
  // {
  //   path: '/cartao-membro',
  //   name: 'Cartao de Membro',
  //   component: () => import('../views/CartaoMembro.vue')
  // },
  // {
  //   path: '/cartao-membro',
  //   name: 'Cartao de Membro',
  //   component: () => import('../views/CartaoMembro.vue')
  // },
  {
    path: '/solicitacoes',
    name: 'Solicitacoes',
    component: () => import('../views/compras/Listagem.vue'),
  },
  {
    path: '/solicitacoes/create',
    name: 'Solicitar create',
    component: () => import('../views/compras/Solicitar.vue')
  },
  {
    path: '/solicitacoes/:id/detalhes',
    name: 'detalhes-solicitacao',
    component: () => import('../views/compras/Detalhes.vue')
  },
  {
    path: '/solicitacoes/success',
    name: 'Solicitacao Enviada',
    component: () => import('../views/Sucess.vue')
  },
  // {
  //   path: '/dados-igreja',
  //   name: 'Dadosigreja',
  //   component: () => import('../views/solicitarAcesso/DadosIgreja.vue')
  // },
  // {
  //   path: '/dados-pessoais',
  //   name: 'DadosPessoais',
  //   component: () => import('../views/solicitarAcesso/DadosPessoais.vue')
  // },
  // {
  //   path: '/dados-pessoais2',
  //   name: 'DadosPessoais2',
  //   component: () => import('../views/solicitarAcesso/DadosPessoais2.vue')
  // },
  {
    path: '/sucesso',
    name: 'Sucesso',
    component: () => import('../views/solicitarAcesso/Sucesso.vue')
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('../views/Error.vue'),
    // props: (route) => ({
    //   title: route.query.title,
    //   subtitle: route.query.subtitle,
    // })
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Adicione um observador de rota
router.beforeEach(async (to, from, next) => {
  // Acesse a query string da rota atual
  let token = to.query.tokenLaravel || '';
  let embed = typeof to.query.embed != 'undefined' && to.query.embed == true;

  if(token == ''){
    token = store.getters.getTokenLaravel;

    if(token !== ''){
      embed = true;
    }
  }

  store.dispatch('setTokenLaravel', token);
  store.dispatch('setShowMenu', !embed);

  if (to.path.startsWith('/solicitacoes')) {
    let res = await store.dispatch('fetchUser', {
      Authorization: `Bearer ${token}`
    });

  }

  // Continue a navegação
  next();
})

export default router
