export const actions = {
    setError({ commit }, error) {
        commit("SET_ERROR", error);
    },
    setSuccess({ commit }, success) {
        commit("SET_SUCCESS", success);
    },
    setErrors({ commit }, errors) {
        commit("SET_ERRORS", errors)
    },
}