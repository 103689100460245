export const mutations = {
    SET_TOKEN_LARAVEL(state, tokenLaravel) {
        state.tokenLaravel = tokenLaravel;
    },
    SET_SHOW_MENU(state, showMenu) {
        state.showMenu = showMenu;
    },
    SET_USER(state, user) {
        state.user = user;
    }
};