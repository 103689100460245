export function jsonToGraphQLMutation(obj) {
    let texto = '';
    for (const chave in obj) {
        if (obj.hasOwnProperty(chave)) {
            const valor = obj[chave];

            // Verifica se o valor não é vazio
            if (valor !== null && valor !== undefined) {
                if (Array.isArray(valor)) {
                    const arrayTextos = valor.map(item => {
                        if (typeof item === 'object') {
                            return `{ ${jsonToGraphQLMutation(item)} }`;
                        } else {
                            return `${item}`;
                        }
                    });
                    texto += `${chave}: [${arrayTextos.join(', ')}], `;
                } else if (typeof valor === 'object') {
                    texto += `${chave}: { ${jsonToGraphQLMutation(valor)} }, `;
                } else {
                    // Verifica se o valor é uma string
                    if (typeof valor === 'string') {
                        // Formata a string com quebras de linha
                        if (chave === 'status_id') {
                            texto += `${chave}: "${valor.replace(/"/g, '\\"')}", `;
                        } else {
                            texto += `${chave}: """${valor.replace(/"/g, '\\"')}""", `;
                        }
                    } else {
                        texto += `${chave}: ${valor}, `;
                    }
                }
            }
        }
    }
    texto = texto.slice(0, -2);
    return texto;
}
