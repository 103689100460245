import HttpClient from './HttpClient';
import config from '../config/enviroment.js';

const endpoint = '/graphql'

class GraphQLService {
    constructor(headers = {}) {
        this.httpClient = new HttpClient(
            config.config.api_url+ endpoint
            , headers);
    }

    async request(query, variables = {}, headers = {}) {
        try {
            const response = await this.httpClient.post('', {
                query,
                variables,
            }, headers);

            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default GraphQLService;