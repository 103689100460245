<template>
  <div>
    <Menu v-show="1 == 2" />
    <router-view />
  </div>
</template>

<script>
import Menu from './components/Menu.vue'
export default {

  name: 'App',
  components: {
    Menu
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  font-family: 'Poppins';
  margin: 0px;
  padding: 0px;
}

.el-pager li {
    font-size: 20px;
}

.el-tag.el-tag--info {
    float: left;
}

.el-tabs__nav-scroll {

  display: flex;
  overflow: hidden;
  justify-content: center;
}

.el-tabs--card>.el-tabs__header {
  border-bottom: none;
  height: var(--el-tabs-header-height);
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: none;
}

/* .el-switch.is-checked.mb-2 {
  margin-bottom: 10px;
} */

.el-tabs--card>.el-tabs__header .el-tabs__nav {
  border: none;
}

.el-tabs--card>.el-tabs__header .el-tabs__item,
.is-active {
  border: none;
  border-bottom-color: none;
}

.el-tabs--card>.el-tabs__header .el-tabs__item {
  color: #D9DBDC;
}

.el-tabs--card>.el-tabs__header .el-tabs__item:hover {
  color: #0EA2D9;
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  color: #0EA2D9;
}

/* .el-tabs__item .is-bottom, .is-active {
  padding: 0px;
} */

.el-tabs--bottom .el-tabs--left>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--bottom .el-tabs--right>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--bottom.el-tabs--border-card>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--bottom.el-tabs--card>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--top .el-tabs--right>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:last-child {
  padding: 0px;
}

.el-tabs__item {
  padding: 0 !important;

}

/* .el-switch.is-checked.mb-2 {
  margin-bottom: 10px;
} */

.el-input-number .el-input__inner {
  width: 30px;
}

.el-input-number.is-controls-right .el-input__wrapper {
  width: 10px;
}

.el-tabs__item.is-bottom {
  font-size: 30pt !important;
}

.upload-demo {
  height: 160px;
  overflow: auto;
}

.title-section {
  margin-bottom: 20px;
  color: #114C74;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.el-textarea__inner {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.el-input-number--large {
  width: 100%;
}

.my-btn-primary {
  width: 100%;
  height: 60px;
  background-color: #116C9E;
  border-radius: 8px;
  font-size: 16px;
}

.el-input-number.is-controls-right[class*=large] [class*=decrease],
.el-input-number.is-controls-right[class*=large] [class*=increase] {
  --el-input-number-controls-height: 29px;
  width: 30px;
  font-weight: bolder;
  border: none;
  font-size: 20px;
  color: black;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

body {
  background-color: #F1F5F9;
}

.corpo,
.container {
  margin-top: 20px !important;
}

div.corpo {
  width: 360px;
  margin: auto;
}

.el-input__wrapper {
  border-radius: 8px !important;
  min-height: 60px;
}

.el-button {
  font-size: 16px;
}

.el-carousel__indicator.is-active button {
  opacity: 1;
  background-color: black;
}

.vermais-vermenos {
  cursor: pointer;
  color: #114C74;
}


.card .titulo {
  margin-bottom: 20px;
  color: #114C74;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.card .card-itens {
  width: 100% !important;
  margin: auto;
  background: white;
  border-radius: 8px;
}

.card .card-itens .itens {
  padding: 20px;
  border: 1px solid #DAE3F2;
}

.card .card-itens .itens h4 {
  color: #114C74;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
}

.card .card-itens .itens p {
  color: #8D99AE;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Sim {
    max-width: 135px;
    width: 100%;
    height: 47px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #CCD4E1;
    background: transparent;
    color: #8D99AE;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Nao {
    max-width: 135px;
    width: 100%;
    height: 47px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #114C74;
    background: #114C74;
    color: white;
}
</style>
