import axios from 'axios';

class HttpClient {
  constructor(baseURL, headers = {}) {
    this.instance = axios.create({
      baseURL,
      headers,
    });
  }

  async get(url, params = {}, headers = {}) {
    try {
      const response = await this.instance.get(url, {
        params,
        headers: { ...this.instance.defaults.headers, ...headers },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async post(url, data = {}, headers = {}) {
    try {
      const response = await this.instance.post(url, data, {
        headers: { ...this.instance.defaults.headers, ...headers },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async put(url, data = {}, headers = {}) {
    try {
      const response = await this.instance.put(url, data, {
        headers: { ...this.instance.defaults.headers, ...headers },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async delete(url, headers = {}) {
    try {
      const response = await this.instance.delete(url, {
        headers: { ...this.instance.defaults.headers, ...headers },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default HttpClient;