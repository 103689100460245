export const mutations = {
    SET_DATA_COMPRAS(state, data) {
        state.data = data;
    },
    SET_COMPRA(state, compra) {
        state.compra = compra;
    },
    SET_STATUS(state, status) {
        state.status = status;
    },
    SET_TIPOS(state, tipos) {
        state.tipos = tipos;
    },
    SET_PARECER_COORDENADOR(state, parecerCoordenador) {
        state.parecerCoordenador = parecerCoordenador;
    },
    SET_DOCUMENTOS(state, documentos) {
        state.documentos = documentos
    },
    SET_DOCUMENTO(state, documento) {
        state.documentos.push(documento);
    },
    SET_FILTRO_SETORES(state, filtroSetores) {
        state.filtroSetores = filtroSetores;
    },
    SET_FILTRO_CONGREGACOES(state, filtroCongregacoes) {
        state.filtroCongregacoes = filtroCongregacoes;
    },
    SET_COMPRA_ITENS_SUGERIDOS(state, itensSugeridos) {
        state.itensSugeridos = itensSugeridos;
    },
    SET_ITEM_SUGERIDO(state, itemSugerido) {
        state.itemSugerido = itemSugerido;
    },
    SET_FILTRO(state, filtro) {
        state.filtro = filtro;
    }
};